import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper'

Swiper.use([Navigation, Pagination, EffectFade])

export default class extends Controller {
  static targets = ['pagination']

  connect() {
    this.initGallerySlider()
  }

  initGallerySlider() {
    new Swiper('.js-gallerySlider', {
      slidesPerView: 1,
      centeredSlides: true,
      loop: true,
      speed: 800,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
}
